import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="242" height="8" viewBox="0 0 242 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.9916 8C39.9883 7.25735 3.3534 8.08646 0.690549 7.86265C-1.48342 7.56254 1.99077 2.27751 4.00871 1.49925C4.71602 1.21949 5.53776 1.17881 7.42048 1.30597C10.4058 1.54505 49.0066 1.07198 49.0066 1.07198C49.5163 1.23475 51.0454 0.685396 51.3262 0.883775C52.2104 1.34666 114.777 0.715913 120.113 0.69048C123.348 0.67522 120.789 0.99059 124.596 0.863424C132.709 0.609092 150.392 0.853252 156.54 0.563314C161.46 0.324241 162.521 0.314075 167.243 0.507368C172.07 0.680314 197.731 0.431066 200.664 0.0597408C201.382 -0.0419921 201.933 -0.00638251 201.933 0.11061C201.933 0.136044 220.022 0.222513 220.105 0.314073C220.24 0.431066 220.791 0.487015 221.301 0.405629C222.248 0.283549 241.501 -0.15899 241.803 0.181815C242.75 1.15845 240.097 5.31932 237.861 6.33157C235.572 7.41502 223.007 6.19932 212.907 6.85041L165.881 6.91654L155.801 7.32347C154.647 6.97249 150.205 7.85756 147.522 7.22682C146.388 6.97757 138.378 6.94196 136.61 7.20647C135.071 7.40994 94.0982 6.89618 89.8959 7.60323C82.4067 7.01318 52.0336 7.85757 44.9916 8Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="222" height="8" viewBox="0 0 222 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M41.2733 8C36.6835 7.25735 3.07626 8.08646 0.633479 7.86265C-1.36082 7.56254 1.82624 2.27751 3.67741 1.49925C4.32627 1.21949 5.08009 1.17881 6.80721 1.30597C9.5458 1.54505 44.9565 1.07198 44.9565 1.07198C45.4241 1.23475 46.8268 0.685396 47.0844 0.883775C47.8955 1.34666 105.291 0.715913 110.186 0.69048C113.154 0.67522 110.807 0.99059 114.299 0.863424C121.742 0.609092 137.963 0.853252 143.603 0.563314C148.116 0.324241 149.089 0.314075 153.422 0.507368C157.849 0.680314 181.389 0.431066 184.08 0.0597408C184.739 -0.0419921 185.244 -0.00638251 185.244 0.11061C185.244 0.136044 201.838 0.222513 201.914 0.314073C202.039 0.431066 202.544 0.487015 203.012 0.405629C203.88 0.283549 221.543 -0.15899 221.819 0.181815C222.688 1.15845 220.254 5.31932 218.203 6.33157C216.104 7.41502 204.577 6.19932 195.311 6.85041L152.172 6.91654L142.925 7.32347C141.866 6.97249 137.792 7.85756 135.33 7.22682C134.29 6.97757 126.942 6.94196 125.32 7.20647C123.908 7.40994 86.3215 6.89618 82.4665 7.60323C75.5962 7.01318 47.7333 7.85757 41.2733 8Z" fill="#FFA700" />
    </svg>
  </span>
)