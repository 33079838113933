import React from "react"

//logo de empresas clientes
import Ibr from "@components/sharedComponents/customersLogos/logos/Ibr.svg"
import Yarnold from "@components/sharedComponents/customersLogos/logos/Yarnold.svg"
import Recupera from "@components/sharedComponents/customersLogos/logos/Recupera.svg"
import Mornese from "@components/sharedComponents/customersLogos/logos/Mornese.svg"
import LosAndes from "@components/sharedComponents/customersLogos/logos/LosAndes.svg"
import Cobrfec from "@components/sharedComponents/customersLogos/logos/Cobefec.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/como-contestar-llamada-servicio-cliente.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/como-vender-creditos-personales.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/cobranza-preventiva.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/dimensionamiento-call-center.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/control-calidad-call-center.jpg"

// logos testimonios
import IbrTes from "@components/sharedComponents/testimonies/images/logos/ibr.svg"

//foto testimonio desktop
import ibrDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/ibr.png"

//foto testimonio desktop
import ibrMobile from "@components/sharedComponents/testimonies/images/fotosMobile/ibr.png"

//image acordeon left
import ImageAccordionLeftOne from "@components/pagePlatcon/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pagePlatcon/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pagePlatcon/images/accordionLeft/imageThree.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imagePlatcon.jpg"

import imageCaracteristicaOne from "@components/pagePlatcon/operations/images/imageOne.svg"
import imageCaracteristicaTwo from "@components/pagePlatcon/operations/images/imageTwo.svg"

const iconBlue = <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_25010_72581)">
    <path d="M5.07753 9.49998C4.69291 9.49998 4.28266 9.35363 3.97497 9.06095C3.35958 8.47559 3.35958 7.54876 3.97497 6.98778L10.3596 0.91461C10.975 0.329244 11.9493 0.329244 12.5391 0.91461C13.1288 1.49998 13.1545 2.42681 12.5391 2.98778L6.15445 9.08534C5.84676 9.35363 5.46215 9.49998 5.07753 9.49998Z" fill="#90ECF2" />
    <path d="M5.07689 9.50013C4.71791 9.50013 4.3333 9.37818 4.0256 9.10988L0.512785 6.0611C-0.128241 5.52452 -0.153882 4.59769 0.410221 3.98793C0.974323 3.37818 1.94868 3.35379 2.58971 3.89037L6.10253 6.93915C6.74355 7.47574 6.76919 8.40257 6.20509 9.01232C5.8974 9.35379 5.48714 9.50013 5.07689 9.50013Z" fill="#90ECF2" />
  </g>
  <defs>
    <clipPath id="clip0_25010_72581">
      <rect width="13" height="9" fill="white" transform="translate(0 0.5)" />
    </clipPath>
  </defs>
</svg>

const iconRed = <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_25010_72627)">
    <path d="M5.07753 9.49998C4.69291 9.49998 4.28266 9.35363 3.97497 9.06095C3.35958 8.47559 3.35958 7.54876 3.97497 6.98778L10.3596 0.91461C10.975 0.329244 11.9493 0.329244 12.5391 0.91461C13.1288 1.49998 13.1545 2.42681 12.5391 2.98778L6.15445 9.08534C5.84676 9.35363 5.46215 9.49998 5.07753 9.49998Z" fill="#F2ACA9" />
    <path d="M5.07689 9.50013C4.71791 9.50013 4.3333 9.37818 4.0256 9.10988L0.512785 6.0611C-0.128241 5.52452 -0.153882 4.59769 0.410221 3.98793C0.974323 3.37818 1.94868 3.35379 2.58971 3.89037L6.10253 6.93915C6.74355 7.47574 6.76919 8.40257 6.20509 9.01232C5.8974 9.35379 5.48714 9.50013 5.07689 9.50013Z" fill="#F2ACA9" />
  </g>
  <defs>
    <clipPath id="clip0_25010_72627">
      <rect width="13" height="9" fill="white" transform="translate(0 0.5)" />
    </clipPath>
  </defs>
</svg>



let data = {
  start: {
    textbody:
      "Hacemos que las empresas se contacten y comuniquen mejor con sus clientes, con soluciones omnicanales y tecnologías de voz. Aplicamos para todos, grandes operaciones de Contact Center y negocios que buscan escalar.",
    image: "./banner.html",
    textbutton: "Hablar con un experto",
    textlink: "Registrarme a una demo Beex",
    link: "/contact-center/",
  },
  customersDesktop: [
    Ibr,
    Yarnold,
    Recupera,
    Mornese,
    LosAndes,
    Cobrfec
  ],
  customersTablet: {
    arrayOne: [Ibr,
      Yarnold,
      Recupera,
      Mornese,
      LosAndes,
      Cobrfec],
  },
  articles: [
    {
      image: article1,
      title: "¿Cómo contestar una llamada de servicio al cliente?",
      textbody:
        "Conoce cómo funciona la plataforma Beex Conversations, y cómo puedes empezar a crear tu cuenta para ofrecer una buena experiencia omnicanal.",
      link: "https://beexcc.com/blog/como-contestar-llamada-servicio-cliente/",
      card: true,
    },
    {
      image: article2,
      title: "¿Cómo vender créditos personales? 5 pasos para lograrlo",
      textbody:
        "Si quieres saber cómo vender créditos personales, en este artículo te damos 5 de los mejores tips que vas a encontrar en toda la internet.",
      link: "https://beexcc.com/blog/como-vender-creditos-personales/",
      card: true,
    },
    {
      image: article3,
      title: "Cobranza preventiva: Qué es y su importancia para las campañas",
      textbody:
        "Te contamos las mejores estrategias de cobranza preventiva que ayudará a tu negocio a optimizar la solicitud de cobro de deuda.",
      link: "https://beexcc.com/blog/cobranza-preventiva/",
    },
    {
      image: article4,
      title: "¿Cómo hacer un dimensionamiento de call center?",
      textbody:
        "Descubre cómo hacer un dimensionamiento de call center y lograr campañas rentables y mucho más productivas.",
      link: "https://beexcc.com/blog/dimensionamiento-call-center/",
    },
    {
      image: article5,
      title: "Implementa en 6 pasos un control de calidad para call center",
      textbody:
        "Lleva tu atención al cliente a su máxima eficiencia implementando un control de calidad (QA) en cada proceso.",
      link: "https://beexcc.com/blog/control-calidad-call-center/",
    },
  ],
  contacts: {
    title: "Mejora tu contactabilidad",
    textbody:
      "Permítenos compartirte una Demo en tiempo real, mostrándote como Beex valida tus números telefónicos masivamente de tus campañas.",
    href: "/hablemos/",
    btn: "Obtener Demo gratis",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      image: IbrTes,
      title:
        "Con la validación de teléfonos de Beex hemos mejorado la contactabilidad de nuestra cartera",
      textbody:
        "IBR Perú, empresa líder en Contact Center & BPO, ha mejorado sus ratios de contacto gracias a la validación en tiempo real con Machine Learning de Beex.",
      name: "Michael Marcelo",
      post: "Sub-Gerente de Operaciones de IBR Perú",
      link: "/casos-de-exito/ibr-peru/",
      imgDesktop: ibrDesktop,
      imgMobile: ibrMobile,
      metrics: [

      ],
    },
  ],
  accordionLeft: [
    {
      title: "Validación de móviles",
      description: "<p>Valida tus números con una efectividad mayor al 95% y gestiona solo a clientes que tengan más probabilidad de contestar tu llamada. <br/> <br/> Disgrega eficientemente y sin equivocación aquellos teléfonos con los que no tendrás contacto, nuestros algoritmos te aseguran el éxito de este proceso.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Estadísticas",
      description: "<p>Maneja diferentes dashboards y reportes, contando con data en tiempo real y con mucho detalle. <br/> <br/> Te ayudamos a que tomes mejores decisiones en base a los resultados de tus campañas, llega a los clientes correctos y haz que tu negocio tenga mayor éxito.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Mayor contactibilidad",
      description: "<p>Envía campañas masivas por WhatsApp o SMS en minutos. Importa tu base de contactos rápidamente o integra los envíos vía API para enviar campañas de marketing u otros, como ofertas, promociones especiales, recordatorios o actualizaciones.</p>",
      image: ImageAccordionLeftThree,
    },
  ],
  bannerVideo: {
    title: "Marcador Predictivo vs. Progresivo",
    description: "<p>En este video hablamos de marcaciones progresivas y predictivas para campañas telefónicas outbound. Conoce mejor cuándo, donde y porque usarlas. <br /><br />  Nuestro COO, Raúl Ballesteros Samamé, comparte la mejor manera de aplicar segmentaciones a tu base de datos según tu operación, ya sea de televentas, telemarketing o cobranzas.</p>",
    image: imageVideo
  },
  modules: [
    {
      title: "Validación de móviles",
      img: imageCaracteristicaOne,
      text: <p> {iconBlue} Detalle de resultados: Prendidos, buzones de voz e inexistentes.
        <br /> <br />
        {iconBlue} Tiempo record de validación en base al tipo de suscripción.
      <br /> <br />
        {iconBlue} Mucho más rápido que validar con IVR y sin consumo de telefonía.
      </p>,
      colorTab: "3px solid #90ECF2",
      background: "#F4FDFE"
    },
    {
      title: "Dashboards",
      img: imageCaracteristicaTwo,
      text: <p> {iconRed} Detalle de resultados: Prendidos, buzones de voz e inexistentes.
      <br /> <br />
        {iconRed} Tiempo record de validación en base al tipo de suscripción.
      <br /> <br />
        {iconRed} Mucho más rápido que validar con IVR y sin consumo de telefonía.
      </p>,
      colorTab: "3px solid #F2ACA9",
      background: "#FEF7F6"
    },
  ],
}

export default data
