import React, { useEffect, useRef } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"

const Operations = ({ data, location, title }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  return (
    <section className="container-caracterists-validations">
      {title}
      <div className="container-caracterists-validations-cards-desktop">
        {data.map((elemento, i) => {
          return (
            <div
              key={i}
              className="container-caracterists-validations-cards-desktop-item"
              style={{ background: elemento.background, border: elemento.colorTab }}
            >
                <img loading="lazy" src={elemento.img} alt="..." />
                <p className="container-caracterists-validations-cards-desktop-item-info-title">
                  {elemento.title}
                </p>
                <p className="container-caracterists-validations-cards-desktop-item-info-description">
                  {elemento.text}
                </p>            
            </div>
          )
        })}
      </div>

      <div className="container-caracterists-validations-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <div key={i}>
                <div
                  className={`container-caracterists-validations-cards-mobile-item`}
                  style={{ background: elemento.background, border: elemento.colorTab }}>
                  <div className="container-caracterists-validations-cards-mobile-item-info">
                  <img loading="lazy" src={elemento.img} alt="..." />
                    <p className="container-caracterists-validations-cards-mobile-item-info-title">
                      {elemento.title}
                    </p>
                    <p className="container-caracterists-validations-cards-mobile-item-info-description">
                      {elemento.text}
                    </p>
                  </div>
                    
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Operations
