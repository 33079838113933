import React from "react"


const BannerVideo = ({ data, openVideo }) => {
    return (
        <section className="container-banner-video">
            <section className="container-banner-video-info">
                <section className="container-banner-video-info-texts">
                    <svg className="container-banner-video-hexagonoOne" width="154" height="170" viewBox="0 0 154 170" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M-7.73263 -33.0542C3.41946 -49.0446 23.5997 -56.0355 42.2537 -50.3705L120.121 -26.7232C138.775 -21.0583 151.66 -4.02603 152.037 15.4655L153.609 96.8293C153.986 116.321 141.769 133.838 123.347 140.22L46.4519 166.858C28.0307 173.239 7.59542 167.034 -4.16618 151.486L-53.2627 86.5855C-65.0243 71.0379 -65.437 49.6851 -54.2849 33.6947L-7.73263 -33.0542Z" fill="#D3F7FA" />
                    </svg>
                    <div className="container-banner-video-info-description" >
                        <p className="container-banner-video-info-title">
                            {data.title}
                        </p>
                        <div dangerouslySetInnerHTML={{ __html: data.description }} />
                    
                    </div>
                    <svg className="container-banner-video-hexagonoTwo" width="141" height="119" viewBox="0 0 141 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M60.5656 3.91144C71.3572 -2.07385 84.7755 -0.429597 93.8026 7.98426L131.485 43.1063C140.512 51.5202 143.095 64.7899 137.882 75.9752L116.123 122.666C110.911 133.852 99.0887 140.408 86.8401 138.908L35.7104 132.642C23.4618 131.141 13.5726 121.924 11.215 109.811L1.37381 59.2476C-0.983755 47.1347 4.72651 34.8812 15.5181 28.8959L60.5656 3.91144Z" fill="#D3F7FA" />
                    </svg>
                </section>
                <button className="container-banner-video-button-play" onClick={() => openVideo(true)} >
                <svg width="101" height="96" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)"><path d="M61.92 85.262c-.327 0-.658-.014-.986-.037l-26.293-1.988a13.255 13.255 0 0 1-10.913-7.453L12.3 52.005a13.261 13.261 0 0 1 .997-13.185l14.862-21.792A13.252 13.252 0 0 1 40.07 11.3l26.29 1.988a13.252 13.252 0 0 1 10.913 7.456L88.7 44.522a13.261 13.261 0 0 1-.997 13.185L72.838 79.5a13.247 13.247 0 0 1-10.918 5.763Z" fill="#E9FBFC"/><path d="m98.284 37.383-15.117-26.74a20.532 20.532 0 0 0-7.43-7.6A19.954 19.954 0 0 0 65.583.262H35.387a19.955 19.955 0 0 0-10.13 2.79 20.532 20.532 0 0 0-7.415 7.591l-15.1 26.74a21.137 21.137 0 0 0-2.719 10.38c0 3.645.938 7.225 2.719 10.382l15.1 26.718a20.54 20.54 0 0 0 7.432 7.6 19.962 19.962 0 0 0 10.151 2.782h30.197a19.962 19.962 0 0 0 10.126-2.783 20.54 20.54 0 0 0 7.419-7.577l15.1-26.718A21.133 21.133 0 0 0 101 47.777a21.14 21.14 0 0 0-2.716-10.394Zm-28.962 14.03-25.56 14.993c-3.44 2.017-6.23.366-6.23-3.693V32.81c0-4.055 2.79-5.705 6.23-3.688l25.56 14.987c3.444 2.022 3.444 5.288 0 7.305Z" fill="#5831D8"/></g><defs><clipPath id="a"><path fill="#fff" transform="translate(0 .262)" d="M0 0h101v95H0z"/></clipPath></defs></svg>
                </button>
                <img
                    className="container-banner-video-info-image"
                    srcSet={data.image}
                    alt="suport"
                    loading="lazy"
                />
            </section>
        </section>
    )
}
export default BannerVideo
