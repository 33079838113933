import React from "react"
import TemplatePagePlatcon from "@components/pagePlatcon/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div>
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Beex Validation: Software de validación de teléfono con IA [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/validation/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Utiliza la IA para validar los teléfonos de toda tu base de datos en tiempo real para que aumentes la contactabilidad de tus campañas."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Beex Validation: Software de validación de teléfono con IA"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/validation/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/og-platcon.png`}
      />
      <meta
        property="og:description"
        content="Utiliza la IA para validar los teléfonos de toda tu base de datos en tiempo real para que aumentes la contactabilidad de tus campañas."
      />
    </Helmet>
    <TemplatePagePlatcon location={location} />
  </div>
)

export default IndexPage
