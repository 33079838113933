import React from "react"
import { Link } from "gatsby"
import helpCustomer from "./images/imagenContact.webp"
import contactOne from "./images/contactOne.webp"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import {TitleUnderlineOne, TitleUnderlineTwo} from "./titleUnderline"

const IntroHome = ({ location }) => {
  return (
    <section className="container-intro-page-platcon">
      <div className="container-intro-page-platcon-infoTitle">
        <div className="container-intro-page-platcon-infoTitle-title">
       
         <TitleUnderlineOne underline=" Valida tus " /> <TitleUnderlineTwo underline="teléfonos " />   en tiempo real sobre el 95% de éxito
        </div>
        <div className="container-intro-page-platcon-infoTitle-text">
        Contacta de manera eficiente a teléfonos verdaderamente activos y no malgastes tiempo en llamar a teléfonos apagados, suspendidos o inexistentes. 
        </div>
        <div className="container-intro-page-platcon-infoTitle-button">
          <Link
            className="container-intro-page-platcon-infoTitle-buttonOne"
            to={`/hablemos/${location.search}`}
            target="_blank"
            rel="noopener noreferrer"
          >
           Obtener 30 días gratis
          </Link>
        </div>

            </div>
      <div className="container-intro-page-platcon-image">
        <img
          src={helpCustomer}
          alt="customer"
          loading="lazy"
        />
      </div>

      <section className="container-intro-page-platcon-silder">
        <Slider
          dots={true}
          infinite={true}
          slidesToShow={1}
          slidesToScroll={1}
          autoplay={true}
          speed={500}
          autoplaySpeed={18000}
          pauseOnFocus={false}
          draggable={true}
          pauseOnHover={false}
          initialSlide={0}
          arrows={true}
        >
          <img
            src={contactOne}
            alt="customer"
            className="image-help-customermobile"
            loading="lazy"
          />
        </Slider>
      </section>
    </section>
  )
}

export default IntroHome
