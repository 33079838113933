import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg width="223" height="10" viewBox="0 0 223 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M41.3986 8.72359C36.0877 7.89218 3.36222 9.43913 1.03089 9.25814C-1.03589 8.97016 0.497884 3.11684 2.11882 2.21858C2.69353 1.89929 3.43901 1.82316 5.21704 1.93848C6.77648 2.03876 17.5354 1.73267 43.5623 1.08323C44.0699 1.25978 45.3649 0.640535 45.6674 0.858117C45.8986 0.98508 49.6775 0.990716 55.2955 0.961972C61.5994 0.928646 78.7613 0.752941 87.2248 0.707681C99.7158 0.633375 95.018 0.611434 109.317 0.602723C112.308 0.599961 109.968 0.938173 113.486 0.833074C120.99 0.614411 137.381 1.11751 143.06 0.905573C147.616 0.735111 148.608 0.735958 152.98 1.03134C156.478 1.26654 181.173 1.62663 183.966 1.31877C184.639 1.22461 185.148 1.28391 185.131 1.41205C185.423 1.4284 201.856 2.06068 201.927 2.16805C202.041 2.29853 202.536 2.3799 203.018 2.3047C203.896 2.17508 221.766 2.37494 222.008 2.75897C222.688 3.86563 219.589 8.31177 217.426 9.3512C215.16 10.4391 204.102 8.69589 194.581 9.10214C194.581 9.10214 191.898 9.0266 187.873 8.90491C148.571 7.76067 149.907 8.09153 142.404 8.31578C141.346 7.90609 137.318 8.79514 134.835 8.08653C133.804 7.79459 126.497 7.6649 124.858 7.91505C124.507 7.97025 121.91 7.9465 118.132 7.89621C114.368 7.84617 109.424 7.764 104.419 7.73104C101.91 7.71443 99.3863 7.69198 96.9935 7.68861C94.5862 7.6794 92.295 7.70016 90.2664 7.72559C86.1947 7.77619 83.1581 7.87308 82.2208 8.06866C74.8061 7.38979 48.4945 8.4586 41.3986 8.72359Z" fill="#FFBA00" />
    </svg>
  </span>
)

export default titleUnderline
