import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="351" height="11" viewBox="0 0 351 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M65.0994 9.46293C56.9845 8.71657 4.22987 10.8395 1.20724 10.7345C-1.99407 10.4912 1.87729 4.63649 4.64679 3.7077C6.34423 3.14378 6.22511 3.5529 20.8618 3.14378C20.8618 3.14378 42.9285 2.5467 70.0577 1.8501C70.0577 1.8501 113.64 0.948942 173.646 0.738857C178.366 0.722271 174.629 1.07057 180.183 0.948942C192.02 0.700157 217.78 1.17008 226.743 0.971057C233.935 0.810728 235.484 0.799671 242.348 1.10374C247.068 1.31383 286.675 1.83351 291.067 1.51838C292.139 1.44098 292.914 1.49074 292.899 1.6179C293.211 1.62896 319.164 2.38637 319.283 2.50247C319.462 2.63515 320.251 2.71808 321.011 2.65174C322.41 2.52458 350.478 2.91711 350.85 3.30411C351.922 4.40982 347.023 8.77739 343.584 9.78911C340.025 10.834 322.768 8.99853 307.52 9.31366C236.407 7.68273 236.958 8.09737 225.076 8.31299C223.438 7.9094 216.975 8.77739 213.134 8.08632C211.526 7.7933 199.971 7.68273 197.38 7.93152C196.83 7.9868 192.705 7.97574 186.749 7.93705C183.771 7.92046 180.332 7.89282 176.654 7.8707C171.74 7.83753 134.56 7.68826 129.9 8.32957C117.363 7.68826 77.0262 9.07593 65.0994 9.46293Z" fill="#FFBA00" />
    </svg>
  </span>
)

export default titleUnderline
